<template>
  <div>
    <h1>Formation continue</h1>
    <el-row style="width: 820px" v-loading="titreLoadStatus == 1">
      <el-col :span="24">
        <form-fconttitre ref="formFct" />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormFconttitre from "@/components/rh/ui/form/FormFconttitre";
import { strNormalize } from "@/fonctions";

export default {
  components: { "form-fconttitre": FormFconttitre },

  data() {
    return {};
  },
  props: {
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("fctit/loadTitre", this.idFct);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idFct() {
      // récupère le id de la fcontinue dans l'url
      return String(this.$route.params.idfctitre);
    },
    titreLoadStatus() {
      return this.$store.state.fctit.titreLoadStatus;
    },
    titreUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.fctit.titreUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.titreLoadStatus == 2) {
        // charge les données de la fcontinue
        const fct = this.$store.state.fctit.titre;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.fctLoaded = JSON.stringify(fct);
      } else if (this.titreLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formFct.$refs.formFconttitre.validate((valid) => {
        if (valid) {
          const fctdata = this.$store.state.fctit.titre;

          // enlever les espaces avant et après dans le nom de la nouvelle fac
          fctdata.titre = fctdata.titre.trim();

          // vérifie si la fcontinue est  déjà dans la bd
          const FctsInBD = this.$store.getters["fctit/titresInBD"];
          const doublon =
            FctsInBD.filter(
              (m) => m[0] != this.idFct && m[2] == strNormalize(fctdata.titre)
            ).length > 0;
          // prépare les données pour les comparer avec les données avant modifcation et pour l'enregistrement
          const fctdataStr = JSON.stringify(fctdata);

          // vérifie si les données ont été modifiées
          if (localStorage.fctLoaded == fctdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si la fcontinu existe déjà dans la base de donnée
          } else if (doublon) {
            this.$alert(
              "Cette formation continue est déjà enregistrée dans la base de données",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("fctit/updateTitre", fctdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const fctStr = JSON.stringify(this.$store.state.fctit.titre);
      if (localStorage.fctLoaded != fctStr) {
        // si les données ont été modifiées
        let dataObj = JSON.parse(localStorage.fctLoaded);
        this.$store.commit("fctit/setTitre", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modifcation
      const fct = this.$store.state.fctit.titre;
      const fctStr = JSON.stringify(fct);

      if (localStorage.fctLoaded == fctStr) {
        // si pas de modifications
        // retourne à la liste des formations post-graduées
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des fcontinues
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    titreLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    titreUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.titreUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.fctLoaded = JSON.stringify(this.$store.state.fctit.titre);
      } else if (this.titreUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
